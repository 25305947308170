const styles =({
  buttonBackgroundColor = "#113484 !important",
  buttonTextColor = "#fff !important",
})=> `
  #appmaker-smart-banner {
    display: none;
  }
  
  @media only screen and (max-width: 767.9px) {
    #appmaker-smart-banner.appbanner-main-container {
      position: relative;
      line-height: 20px;
      display: flex;
      font-family: sans-serif;
      background-color: #fcfcfc;
      height: 70px;
      min-width: 100%;
      transition: all 0.25s ease;
      box-shadow: 0 1px 5px rgba(213, 212, 255, 0.747);
      z-index: 999999999;
    }
  
    #appmaker-smart-banner .appbanner-container {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 5px;
    }
  
    #appmaker-smart-banner .close-accessibility-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100%;
    }
  
    #appmaker-smart-banner img.apmkr-img {
      width: 46px;
      height: 46px;
      border-radius: 5px;
    }
  
    #appmaker-smart-banner .appbanner-content-container {
      margin: 0 8px;
      padding: 12px 0;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    #appmaker-smart-banner .appbanner-content-container .appbanner-title {
      font-size: 18px;
      font-weight: bold;
      color: #212121;
      line-height: 20px;
    }
    #appmaker-smart-banner .appbanner-content-container .appbanner-discription {
      opacity: 0.8;
      font-size: 12px;
      font-weight: normal;
      line-height: 14px;
      color: #212121;
    }
  
    #appmaker-smart-banner .appbanner-button-container {
      justify-content: end;
      align-items: center;
      margin-right: 14px;
      margin-left: auto;
    }
  
    #appmaker-smart-banner .appbanner-button-container a.appbanner-button {
      background-color: ${buttonBackgroundColor};
      padding: 10px 15px !important;
      color: ${buttonTextColor};
      border-radius: 4px !important;
      border: none !important;
      font-size: 14px !important;
      text-decoration: none !important;
    }
  }`;
const Style = ({
  customStyles={}
}) => <style>{styles(customStyles)}</style>;
export default Style;
