const clientIdKey = 'smartBannerClientId';
const measurement_id = `G-4F2VFM15X9`;
// Test Id
// const measurement_id = `G-J50NP9NDNT`;
function getClientId() {
    const clientId = localStorage.getItem(clientIdKey);
    if (clientId) {
        return clientId;
    }
    const cid = `${~~(2147483648 * Math.random())}.${~~(Date.now() / 1000)}`
    localStorage.setItem(clientIdKey, cid);
    return cid


}
export async function sendGa4(type, eventName, params) {
    // const cid = getClientId();
    const cid = getClientId();
    const dataPageview = {
        v: "2",
        tid: measurement_id,
        gtm: "2oe5b0",
        _p: "215214153",
        _z: "ccd.tbB",
        cid,
        ul: navigator.language.toLowerCase(),
        sr: `${screen.width}x${screen.height}`,
        _s: "1",
        sid: Math.floor(Date.now() / 1000),
        sct: "1",
        seg: "1",
        dr: document.referrer,
        // dt: document.title,
        dt: location.hostname,
        dl: location.href,
        en: "page_view",
        'ep.hostname': location.hostname,
    }
    const data = dataPageview;

    // data['ep.debug_mode'] = true

    if (eventName) data.en = eventName
    if (params) {
        const paramKeys = Object.keys(params);
        paramKeys.forEach(key => {
            data[`ep.${key}`] = params[key];
        });
    }
    const quertParams = new URLSearchParams(data);
    if (navigator && navigator.sendBeacon) {
        navigator.sendBeacon(`https://www.google-analytics.com/g/collect?${quertParams.toString()}`, "");
    }
    

}
export function sendEvent(name, params) {
    try {
        sendGa4('event', name, params);
    } catch (error) {
        console.log(error);
    }
}