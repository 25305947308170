let poly = require("preact-cli/lib/lib/webpack/polyfills");
import MobileDetect from "mobile-detect";
const md = new MobileDetect(window.navigator.userAgent);

import { render } from "preact";
// import habitat from "preact-habitat";

import Widget from "./components/smartBanner";
import { sendEvent } from "./helper/analytics";

const appSmartBanner = (window) => {
  const renderElement = document.createElement("div");
  renderElement.setAttribute("id", "appmaker-smartbanner-preact-root");
  document.body.prepend(renderElement);
  // set default configurations
  let configurations = {
    appName: "",
    subText: "",
    showAfter: 3000,
    CTAText: "Download App",
    plaform: md.os()
  };
  
  // all methods that were called till now and stored in queue
  // needs to be called now
  let globalObject = window[window["AppmakerSmartBannerObject"]];
  let queue = globalObject.q;
  if (queue) {
    for (let i = 0; i < queue.length; i++) {
      if (queue[i][0].toLowerCase() == "init") {
        configurations = {
          ...configurations,
          ...queue[i][1],
        };
        sendEvent("page_view");
        sendEvent("widget_init", { plaform: md.os() });
        if (md.os() === "iOS")
          configurations.downloadUrl =
            configurations.urls && configurations.urls.ios;
        else
          configurations.downloadUrl =
            configurations.urls && configurations.urls.android;
        if (configurations.downloadUrl)
          render(<Widget {...configurations} />, renderElement);
      }
    }
  }
};

appSmartBanner(window);
