import { h } from "preact";

import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useEffect, useState } from "preact/hooks";
import Style from "./style";
import { sendEvent } from "../../helper/analytics";
const viewKey = "bf9b0c79-16ed-4ab4-8186-32d77a1b98ca";
const convertToBoolean = (value) => {
  if (typeof value === 'boolean') {
    return value;
  }
  return value === '1'

}
const Widget = ({
  plaform,
  CTAText = "Download App",
  appIcon,
  appName,
  subText,
  downloadUrl,
  showAfter = 2500,
  defaultReadyToShow = false,
  forceSmartBanner = false,
  limitSmartBannerPreview = 3,
  customStyles,
  hidePaths = ['cart', 'checkout', 'login', 'register', 'account', 'signup', 'sign-up', 'join', 'payment'],
}) => {
  // const [visible, setVisibilty] = useState(true);
  forceSmartBanner = convertToBoolean(forceSmartBanner);
  const urlParams = new URLSearchParams(window.location.search);
  const pathName = window.location.pathname || "/"
  const appmakerSmartBannerKey = urlParams.get("appmakerSmartBannerKey");
  let isHidePath = hidePaths.find(element => {
    if (pathName.includes(element)) {
      return true;
    }
  });
  isHidePath = isHidePath !== undefined;

  // Limit the banner preview
  const [limit, setLimit] = useLocalStorage("limit-force-banner");
  if (limit === undefined && forceSmartBanner) {
    setLimit(limitSmartBannerPreview);
  }

  const limitFlag = forceSmartBanner && parseInt(limit) > 0;
  const [visible, setVisibilty] =
    viewKey === appmakerSmartBannerKey || limitFlag
      ? useState(true)
      : useLocalStorage("appmaker-smart-banner-visibilty", true);

  const [readyToShow, setReadyToShow] = useState(isHidePath ? false : defaultReadyToShow);

  const closeHandler = () => {
    sendEvent('widget_close', { plaform })
    setVisibilty(false);
    if (forceSmartBanner) {
      setLimit(parseInt(limit) - 1);
    }
    return;
  }
  useEffect(() => {
    setTimeout(() => {
      setReadyToShow(!isHidePath);
    }, showAfter);
  }, [isHidePath, showAfter]);
  useEffect(() => {
    if (visible) {
      sendEvent('widget_visible', { plaform })
    }
  }, [visible])

  return (
    readyToShow &&
    visible && (
      <div id="appmaker-smart-banner" class="appbanner-main-container">
        <div
          class="appbanner-close-container appbanner-container"
          onClick={closeHandler}
        >
          <div class="close-accessibility-overlay" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1 1"
            version="1.1"
            width="7"
            height="7"
          >
            <path d="M0 0 L1 1 M1 0 L0 1" stroke-width="0.1" stroke="#000" />
          </svg>
        </div>
        <div class="appbanner-logo-container appbanner-container">
          <img class="apmkr-img" src={appIcon} alt={appName} />
        </div>
        <div class="appbanner-content-container appbanner-container">
          <div class="appbanner-title">{appName}</div>
          <div class="appbanner-discription">{subText}</div>
        </div>
        <div class="appbanner-button-container appbanner-container">
          <a href={downloadUrl} className="appbanner-button" onClick={() => { sendEvent('widget_click', { plaform, url: downloadUrl }) }}>
            {CTAText}
          </a>
        </div>
        <Style customStyles={customStyles} />
      </div>
    )
  );
};
export default Widget;
